import { FC } from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import TextError from './TextError';
import { Dropdown } from 'primereact/dropdown';

type SelectProps = Extract<IFormControlProps, { control: 'select' }>;

/**
 * Select component.
 *
 * @param {SelectProps} props - Component properties.
 */
const Select: FC<SelectProps> = (props) => {
  return (
    <Field name={props.name}>
      {({ field, meta, form }: FieldProps) => {
        const { label, name, options, mandatory, ...rest } = props;

        return (
          <div className="flex flex-col gap-2.5">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <label htmlFor={name} className="!text-sm text-neutral-700">
                  {label}
                </label>

                {mandatory && (
                  <span className="-translate-y-1 text-xs text-red-500">*</span>
                )}
              </div>

              <ErrorMessage name={name} component={TextError} />
            </div>

            <Dropdown
              options={options}
              className={`!text-sm ${meta.error && 'p-invalid'}`}
              optionLabel="label"
              {...field}
              {...rest}
              value={meta.value ? meta.value : null}
              onChange={(e) => {
                console.log(e.value);
                form.setFieldValue(name, e.value);
              }}
            />
          </div>
        );
      }}
    </Field>
  );
};

export default Select;
