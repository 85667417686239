// Import necessary css, components & hooks
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { PrimeReactProvider } from 'primereact/api';
import App from './App.tsx';
import 'primeicons/primeicons.css';
import 'config/theme.css';
import { keycloak } from 'config/Keycloak.ts';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { APP_MODE } from 'config/Constants.ts';

// Create a new instance of QueryClient
const queryClient = new QueryClient();

// Render the application
ReactDOM.createRoot(document.getElementById('root')!).render(
  // Provide the Keycloak authentication client
  <ReactKeycloakProvider
    authClient={keycloak}
    // Keycloak initialization options
    initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
  >
    {/* Strict mode to highlight potential problems */}
    <React.StrictMode>
      {/* Providing QueryClient context */}
      <QueryClientProvider client={queryClient}>
        {/* Providing PrimeReact context */}
        <PrimeReactProvider>
          {/* Rendering the main application component */}
          <App />
        </PrimeReactProvider>
        {APP_MODE === 'dev' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </React.StrictMode>
  </ReactKeycloakProvider>
);
