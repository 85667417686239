/* eslint-disable @typescript-eslint/naming-convention */
export const KEYCLOAK_URL = import.meta.env.VITE_KEYCLOAK_URL;
export const KEYCLOAK_REALM = import.meta.env.VITE_KEYCLOAK_REALM;
export const KEYCLOAK_CLIENT_ID = import.meta.env.VITE_KEYCLOAK_CLIENT_ID;
export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
export const APP_MODE = import.meta.env.VITE_APP_MODE as
  | 'dev'
  | 'prod'
  | 'qa'
  | 'uat'
  | 'pre-prod'
  | 'prod';

export const ACCOUNT_TYPE = [
  {
    label: 'Profit',
    value: 'profit',
  },
  {
    label: 'Non-Profit',
    value: 'non-profit',
  },
  {
    label: 'Academic/University',
    value: 'academic/university',
  },
  {
    label: 'Government',
    value: 'government',
  },
  {
    label: 'Others',
    value: 'others',
  },
];

export const ROLE_TYPE = [
  {
    label: 'Exin Health Admin',
    value: 'exin_health_admin',
  },
  {
    label: 'Account Admin',
    value: 'account_admin',
  },
  {
    label: 'Hospital IT Admin',
    value: 'hospital_it_admin',
  },
  {
    label: 'Hospital User',
    value: 'hospital_user',
  },
  {
    label: 'Edge Box User',
    value: 'edgebox_user',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const DEVICE_STATUS = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'in-active',
  },
  {
    label: 'Faulted',
    value: 'faulted',
  },
  {
    label: 'Paused',
    value: 'paused',
  },
];

// [“Human Pose Estimation”, “Hand Pose Estimation”, “Human 3D Pose Estimation”, “Instance Segmentation for Anonymisation”, “Item Detection”, “Text Detection”, “Genitalia Detection”, “Face Detection”, “Text Anonymisation”, “General Purpose LLM”]

export const MODAL_TYPE = [
  {
    label: 'Image Models',
    value: 'Image Models',
  },
  {
    label: 'Audio Models',
    value: 'Audio Models',
  },
];

export const COMPRESSED_TYPE = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

export enum DeviceStatus {
  ACTIVE = 'active',
  IN_ACTIVE = 'in-active',
  FAULTED = 'faulted',
  PAUSED = 'paused',
}

export enum ORStatus {
  ACTIVE = 'active',
  PAUSED = 'paused',
  FAULTED = 'faulted',
}

export const OR_STATUS = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Faulted',
    value: 'faulted',
  },
  {
    label: 'Paused',
    value: 'paused',
  },
];

export const statuses = [
  { key: 'active', label: 'Active', severity: 'success' },
  { key: 'paused', label: 'Paused', severity: 'warning' },
  { key: 'faulted', label: 'Faulted', severity: 'danger' },
  { key: 'in-active', label: 'Inactive', severity: 'info' },
];

export enum TrendTypes {
  up = 'up',
  down = 'down',
}

export type ComparisonPeriodTypes = {
  key: string;
  name: string;
  disabled: boolean;
};

export const comparisonPeriods: ComparisonPeriodTypes[] = [
  {
    name: 'Month over Month',
    key: 'month_over_month',
    disabled: false,
  },
  {
    name: 'Quarter over Quarter',
    key: 'quarter_over_quarter',
    disabled: true,
  },
  {
    name: 'Year over Year',
    key: 'year_over_year',
    disabled: true,
  },
];

export const SurgicalPhaseTimesMap = new Map<string, string>([
  ['A', 'Turnover'],
  ['B', 'Wheels in to Procedure Start'],
  ['C', 'Procedure Start to Procedure End'],
  ['D', 'Procedure End to Wheels Out'],
]);

export const OrActionsEnum = new Map<string, string>([
  ['A', 'Turnover'],
  ['B', 'Wheel In'],
  ['C', 'Procedure Start'],
  ['D', 'Wheel Out'],
  ['PST', 'Patient moved to surgical table'],
  ['PFT', 'Patient moved from surgical table'],
  ['FIC', 'First incision cut happens'],
  ['CCWI', 'Case cart is wheeled in'],
  ['MOP', 'MOP'],
  ['SBS', 'Sterile backtable setup'],
  ['PWI', 'Patient is wheeled in'],
  ['PWO', 'Patient is wheeled out'],
  ['TOP', 'Timeout procedure'],
  ['ETI-ON', 'ETI-ON'],
  ['ETI-OFF', 'ETI-OFF'],
  ['PD', 'PD'],
  ['BA', 'BA'],
  ['SP', 'SP'],
  ['FIM-ON', 'FIM-ON'],
  ['ORLC', 'ORLC'],
  ['PDR', 'PDR'],
  ['GWN', 'GWN'],
  ['FIM-OFF', 'FIM-OFF'],
  ['SC', 'Skin closure'],
  ['TR', 'TR'],
  ['ETC', 'ETC'],
  ['ITR', 'ITR'],
  ['MHC', 'MHC'],
  ['SE', 'SE'],
  ['ID', 'ID'],
  ['EF', 'EF'],
  ['MFM', 'MFM'],
]);
