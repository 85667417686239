import FormControl from 'components/Formik/FormControl';
import { Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { edgeBoxIcon } from 'assets/icons';
import { Toast } from 'primereact/toast';
import { useUpdateUser } from 'api/services/users';
import { useAuth } from 'hooks/useAuth';

type IUpdateProfileProps = {
  userData: IUser;
};

// Validation schema for profile information
const validationSchema = z.object({
  image: z.string().optional(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  countryCode: z.string(),
  phone: z.string(),
});

/**
 * UpdateProfile component allows users to update their profile information.
 * It uses Formik for form handling, zod for validation, and other hooks for state management.
 *
 * @component
 * @param {object} IUpdateProfileProps - Props for UpdateProfile component.
 * @param {object} IUpdateProfileProps.userData - User data to pre-fill the form.
 *
 */
const UpdateProfile: FC<IUpdateProfileProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const toast = useRef<Toast | null>(null);
  const { userEmail } = useAuth();

  type IProfile = z.infer<typeof validationSchema>;

  // Initial form values based on user data
  const initialValues: IProfile = {
    firstName: props.userData.profile?.firstName || '',
    lastName: props.userData.profile?.lastName || '',
    email: props.userData.profile?.email || userEmail || '',
    countryCode: props.userData.profile?.countryCode || '',
    phone: props.userData.profile?.phone || '',
    image: `data:image/png;base64,${props.userData.profile?.image}` || '',
  };

  const {
    mutate: updateUser,
    isPending: isPendingUpdate,
    isError: isErrorUpdate,
    isSuccess: isSuccessUpdate,
  } = useUpdateUser();

  // Form submission handler
  const handleSubmit = useCallback(
    (values: IProfile) => {
      updateUser({
        data: values,
        userId: props.userData.id,
      });
    },
    [updateUser, props.userData.id]
  );

  useEffect(() => {
    // Show toast messages based on API response
    if (isErrorUpdate) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Error while updating info',
        life: 3000,
      });
    }
    if (isSuccessUpdate) {
      toast?.current?.show({
        severity: 'success',
        summary: 'Info updated successfully',
        life: 3000,
      });
      setVisible(false);
    }
  }, [isErrorUpdate, isSuccessUpdate]);

  return (
    <div>
      {/* Toast component for showing success/error messages */}
      <Toast ref={toast} />

      {/* Button to open the sidebar */}
      <Button
        icon="pi pi-pencil"
        label="Update Info"
        onClick={() => setVisible(true)}
        className="!scale-75"
      />

      {/* Sidebar component for profile update form */}
      <Sidebar
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
        showCloseIcon={false}
        className="!relative w-96"
        header={
          <div className="flex w-full items-center border-b !px-0 pb-3">
            <span>{edgeBoxIcon}</span>
            <span className="ml-4 text-lg font-semibold">Update Profile</span>
            <button
              className="pi pi-times ml-auto cursor-pointer"
              onClick={() => setVisible(false)}
            ></button>
          </div>
        }
      >
        {/* Formik form for updating profile information */}
        <Formik
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(validationSchema)}
          onSubmit={(values) => handleSubmit(values)}
        >
          <Form id="updateProfileForm">
            <div className="mb-6 space-y-4">
              {/* Form control for profile picture */}
              <FormControl
                control="dropzone"
                name="image"
                label="Profile Picture"
                maxSize={4194304}
              />

              {/* Form controls for other profile information */}
              <FormControl
                control="input"
                type="text"
                name="firstName"
                label="First Name"
                placeholder="Enter First Name"
                mandatory={true}
              />

              <FormControl
                control="input"
                type="text"
                name="lastName"
                label="Last Name"
                placeholder="Enter Last Name"
                mandatory={true}
              />

              <FormControl
                control="input"
                type="email"
                name="email"
                label="Email"
                placeholder="Enter email id"
                mandatory={true}
                disabled
              />

              <FormControl
                control="phoneNumber"
                label="Phone Number"
                countryCodeFieldName="countryCode"
                name="phone"
                mandatory={true}
              />
            </div>
            {/* Submit button */}
            <div className="flex justify-end border-t pt-4">
              <Button
                label="Update"
                icon="pi pi-sync"
                size="small"
                form="updateProfileForm"
                type="submit"
                loading={isPendingUpdate}
              />
            </div>
          </Form>
        </Formik>
      </Sidebar>
    </div>
  );
};

export default UpdateProfile;
