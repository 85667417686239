import { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Layout from 'components/Layout/Layout.tsx';
import ErrorPage from 'pages/SharedPages/ErrorPage';
import Fallback from 'components/Fallback';

// Lazy-loaded components
const ExinUserDashboard = lazy(
  () => import('pages/ExinUser/ExinUserDashboard')
);
const Accounts = lazy(() => import('pages/ExinUser/Accounts'));
const HospitalLists = lazy(() => import('pages/ExinUser/HospitalLists'));
const HospitalDetails = lazy(() => import('pages/ExinUser/HospitalDetails'));
const EdgeBoxDetails = lazy(() => import('pages/ExinUser/EdgeBoxDetails'));
const ExinUserAdmin = lazy(() => import('pages/ExinUser/ExinUserAdmin'));
const Procedures = lazy(() => import('pages/SharedPages/Procedures'));
const ProcedureDetailsPage = lazy(
  () => import('pages/SharedPages/ProcedureDetailsPage')
);
const SurgicalNotes = lazy(
  () => import('pages/SharedPages/AdminOperations/SurgicalNotes')
);
const WorkflowEfficiency = lazy(
  () => import('pages/SharedPages/WorkflowEfficiency')
);
const InfectionControl = lazy(
  () => import('pages/SharedPages/InfectionControlPage')
);
const PatientSafety = lazy(() => import('pages/SharedPages/PatientSafetyPage'));
const InventoryManagement = lazy(
  () => import('pages/SharedPages/InventoryMngmtPage')
);
const Culture = lazy(() => import('pages/SharedPages/StaffWellBeing'));

const ProceduresAdmin = lazy(
  () => import('pages/SharedPages/AdminOperations/ProceduresAdmin')
);
const ProcedureDetail = lazy(
  () =>
    import('pages/SharedPages/AdminOperations/ProceduresAdmin/ProcedureDetail')
);
const ViewRecording = lazy(
  () =>
    import('pages/SharedPages/AdminOperations/ProceduresAdmin/ViewRecording')
);
// Define exin user routes using createBrowserRouter
export const exinUserRouter = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Fallback />}>
            <ExinUserDashboard />
          </Suspense>
        ),
      },
      {
        path: 'workflowefficiency',
        element: (
          <Suspense fallback={<Fallback />}>
            <WorkflowEfficiency />
          </Suspense>
        ),
      },
      {
        path: 'infectioncontrol',
        element: (
          <Suspense fallback={<Fallback />}>
            <InfectionControl />
          </Suspense>
        ),
      },
      {
        path: 'patientsafety',
        element: (
          <Suspense fallback={<Fallback />}>
            <PatientSafety />
          </Suspense>
        ),
      },
      {
        path: 'inventorymanagement',
        element: (
          <Suspense fallback={<Fallback />}>
            <InventoryManagement />
          </Suspense>
        ),
      },
      {
        path: 'culture',
        element: (
          <Suspense fallback={<Fallback />}>
            <Culture />
          </Suspense>
        ),
      },
      {
        path: '/procedures',
        element: (
          <Suspense fallback={<Fallback />}>
            <Procedures />
          </Suspense>
        ),
      },
      {
        path: 'procedures/details/:procedureId',
        element: (
          <Suspense fallback={<Fallback />}>
            <ProcedureDetailsPage />
          </Suspense>
        ),
      },
      {
        path: '/accounts',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Fallback />}>
                <Accounts />
              </Suspense>
            ),
          },
          {
            path: '/accounts/:accountId',
            element: (
              <Suspense fallback={<Fallback />}>
                <HospitalLists />
              </Suspense>
            ),
          },
          {
            path: '/accounts/:accountId/:hospitalId',
            element: (
              <Suspense fallback={<Fallback />}>
                <HospitalDetails />
              </Suspense>
            ),
          },
          {
            path: '/accounts/:accountId/:hospitalId/:edgeboxId',
            element: (
              <Suspense fallback={<Fallback />}>
                <EdgeBoxDetails />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: '/admin',
        element: (
          <Suspense fallback={<Fallback />}>
            <ExinUserAdmin />
          </Suspense>
        ),
      },
      {
        path: '/operations',
        children: [
          {
            path: '/operations/procedures-admin',
            element: (
              <Suspense fallback={<Fallback />}>
                <ProceduresAdmin />
              </Suspense>
            ),
          },
          {
            path: '/operations/procedures-admin/:procedureId',
            element: (
              <Suspense fallback={<Fallback />}>
                <ProcedureDetail />
              </Suspense>
            ),
          },
          {
            path: '/operations/procedures-admin/:procedureId/:deviceId',
            element: (
              <Suspense fallback={<Fallback />}>
                <ViewRecording />
              </Suspense>
            ),
          },
          {
            path: '/operations/notifications',
            element: (
              <Suspense fallback={<Fallback />}>
                <SurgicalNotes />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
]);
