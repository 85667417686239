import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import axiosInstance from 'api/index';

type IGetAllUserQueryParams = {
  limit: number;
  page: number;
  name?: string;
  sortBy?: string;
  sortTypes?: string;
};

type IGetAllUserApiResponse = {
  count: number;
  rows: IUser[];
};

/**
 * Function to get a list of all users.
 *
 * @param {IGetAllUserQueryParams} params - Parameters for getting a list of all users.
 * @returns {Promise<IGetAllUserApiResponse>} - Promise that resolves to the list of all users.
 */
const getAllUser = async (
  params: IGetAllUserQueryParams
): Promise<IGetAllUserApiResponse> => {
  const response = await axiosInstance.get('/users', {
    params: {
      ...params,
    },
  });
  return response.data;
};

/**
 * Hook to use the getAllUser query with React Query.
 *
 * @param {IGetAllUserQueryParams} params - Parameters for getting a list of all users.
 * @param {UseQueryOptions<IGetAllUserApiResponse>} props - Options for the useQuery hook.
 * @returns {QueryResult<IGetAllUserApiResponse>} - Query result for the getAllUser query.
 */
export const useGetAllUser = (
  params: IGetAllUserQueryParams,
  props?: UseQueryOptions<IGetAllUserApiResponse>
) => {
  return useQuery<IGetAllUserApiResponse>({
    queryKey: [
      'all-users',
      params,
      params.page,
      params.limit,
      params.name,
      params.sortBy,
      params.sortTypes,
    ],
    queryFn: () => getAllUser(params),
    ...props,
  });
};

type IGetAllUsersForAHospitalQueryParams = {
  hospitalId: string;
  page?: number;
  limit?: number;
  name?: string;
  role?: string;
  title?: string;
  hospitals?: string;
  sortBy?: string;
  sortTypes?: string;
};

/**
 * Function to get a list of all users for a hospital.
 *
 * @param {IGetAllUsersForAHospitalQueryParams} params - Parameters for getting a list of users for a hospital.
 * @returns {Promise<{ count: number; rows: IUser[] }>} - Promise that resolves to the list of users for the hospital.
 */
const getAllUsersForAHospital = async ({
  hospitalId,
  ...rest
}: IGetAllUsersForAHospitalQueryParams): Promise<{
  count: number;
  rows: IUser[];
}> => {
  const response = await axiosInstance.get(`/hospitals/${hospitalId}/users`, {
    params: {
      ...rest,
    },
  });
  return response.data;
};

/**
 * Hook to use the getAllUsersForAHospital query with React Query.
 *
 * @param {IGetAllUsersForAHospitalQueryParams} params - Parameters for getting a list of users for a hospital.
 * @param {UseQueryOptions<{ count: number; rows: IUser[] }>} props - Options for the useQuery hook.
 * @returns {QueryResult<{ count: number; rows: IUser[] }>} - Query result for the getAllUsersForAHospital query.
 */
export const useGetAllUsersForAHospital = (
  params: IGetAllUsersForAHospitalQueryParams,
  props?: UseQueryOptions<{ count: number; rows: IUser[] }>
) => {
  return useQuery<{ count: number; rows: IUser[] }>({
    queryKey: [
      'hospital-users',
      params,
      params.page,
      params.limit,
      params.hospitalId,
      params.name,
      params.role,
      params.title,
      params.hospitals,
      params.sortBy,
      params.sortTypes,
    ],
    queryFn: () => getAllUsersForAHospital(params),
    enabled: !!params.hospitalId,
    ...props,
  });
};

/** .................................... */

type IGetExportCsvUsers = {
  hospitalId: string;
  enabled?: boolean;
};

const getExportCsvUsers = async ({ hospitalId }: IGetExportCsvUsers) => {
  const response = await axiosInstance.get(
    `/hospitals/${hospitalId}/users/export-csv`
  );
  return response;
};

export const useGetExportCsvUsers = ({
  hospitalId,
  enabled,
}: IGetExportCsvUsers) => {
  return useQuery({
    queryKey: ['export-csv-users', hospitalId],
    queryFn: () => getExportCsvUsers({ hospitalId }),
    enabled,
  });
};

/** .................................... */

type IGetExportCsvAdminUsers = {
  enabled?: boolean;
};

const getExportCsvAdminUsers = async () => {
  const response = await axiosInstance.get(`/users/export-csv`);
  return response;
};

export const useGetExportCsvAdminUsers = ({
  enabled,
}: IGetExportCsvAdminUsers) => {
  return useQuery({
    queryKey: ['export-csv-admin-users'],
    queryFn: () => getExportCsvAdminUsers(),
    enabled,
  });
};

type IGetUserQueryParams = {
  userId: string;
  enabled?: boolean;
};

/**
 * Function to get details of a specific user.
 *
 * @param {IGetUserQueryParams} params - Parameters for getting details of a user.
 * @returns {Promise<IUser>} - Promise that resolves to the details of the user.
 */
const getUser = async ({ userId }: IGetUserQueryParams): Promise<IUser> => {
  const response = await axiosInstance.get(`/users/${userId}`);
  return response.data;
};

/**
 * Hook to use the getUser query with React Query.
 *
 * @param {IGetUserQueryParams} params - Parameters for getting details of a user.
 * @returns {QueryResult<IUser>} - Query result for the getUser query.
 */
export const useGetUser = ({ userId, enabled }: IGetUserQueryParams) => {
  return useQuery<IUser>({
    queryKey: ['users', userId],
    queryFn: () => getUser({ userId }),
    enabled,
  });
};

type IAddUserQueryParams = {
  hospitalId: string;
  data: Partial<IAddUser>;
};

/**
 * Function to add a user to a hospital.
 *
 * @param {IAddUserQueryParams} params - Parameters for adding a user to a hospital.
 * @returns {Promise<void>} - Promise that resolves when the user is added.
 */
const addUser = async ({
  hospitalId,
  data,
}: IAddUserQueryParams): Promise<void> => {
  const response = await axiosInstance.post(
    `/hospitals/${hospitalId}/users`,
    data
  );
  return response.data;
};

/**
 * Hook to use the addUser mutation with React Query.
 *
 * @param {UseMutationOptions<void, unknown, IAddUserQueryParams>} props - Options for the useMutation hook.
 * @returns {MutationResult<void>} - Mutation result for the addUser mutation.
 */
export const useAddUser = (
  props?: UseMutationOptions<void, unknown, IAddUserQueryParams>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['hospital-users'] });
      queryClient.invalidateQueries({ queryKey: ['hospital-details-summary'] });
    },
    ...props,
  });
};

type IUpdateUser = {
  userId: string;
  data: Partial<IAddUser>;
};

/**
 * Function to update details of a specific user.
 *
 * @param {IUpdateUser} params - Parameters for updating details of a user.
 * @returns {Promise<void>} - Promise that resolves when the user details are updated.
 */
const updateUser = async ({ userId, data }: IUpdateUser): Promise<void> => {
  const response = await axiosInstance.put(`/users/${userId}`, data);
  return response.data;
};

/**
 * Hook to use the updateUser mutation with React Query.
 *
 * @param {UseMutationOptions<void, unknown, IUpdateUser>} props - Options for the useMutation hook.
 * @returns {MutationResult<void>} - Mutation result for the updateUser mutation.
 */
export const useUpdateUser = (
  props?: UseMutationOptions<void, unknown, IUpdateUser>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['all-users'] });
    },
    ...props,
  });
};

type DeleteUserParams = {
  userId: string;
};

/**
 * Function to delete a specific user.
 *
 * @param {DeleteUserParams} params - Parameters for deleting a user.
 * @returns {Promise<void>} - Promise that resolves when the user is deleted.
 */
const deleteUser = async ({ userId }: DeleteUserParams): Promise<void> => {
  const response = await axiosInstance.delete(`/users/${userId}`);
  return response.data;
};

/**
 * Hook to use the deleteUser mutation with React Query.
 *
 * @param {UseMutationOptions<void, unknown, DeleteUserParams>} props - Options for the useMutation hook.
 * @returns {MutationResult<void>} - Mutation result for the deleteUser mutation.
 */
export const useDeleteUser = (
  props?: UseMutationOptions<void, unknown, DeleteUserParams>
) => {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, DeleteUserParams>({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['all-users'] });
    },
    ...props,
  });
};

/**
 * Function to get a list of all existing users.
 *
 * @returns {Promise<{ users: IUser[] }>} - Promise that resolves to the list of all existing users.
 */
const getAllExistingUsers = async (): Promise<{ users: IUser[] }> => {
  const response = await axiosInstance.get('/users/all');
  return response.data;
};

/**
 * Hook to use the getAllExistingUsers query with React Query.
 *
 * @param {UseQueryOptions<{ users: IUser[] }>} props - Options for the useQuery hook.
 * @returns {QueryResult<{ users: IUser[] }>} - Query result for the getAllExistingUsers query.
 */
export const useGetAllExistingUsers = (
  props?: UseQueryOptions<{ users: IUser[] }>
) => {
  return useQuery<{ users: IUser[] }>({
    queryKey: ['existing-users'],
    queryFn: () => getAllExistingUsers(),
    ...props,
  });
};

type IAddExistingUserQueryParams = {
  hospitalId: string;
  data: Partial<IExistingUsers>;
};

/**
 * Function to add existing users to a hospital.
 *
 * @param {IAddExistingUserQueryParams} params - Parameters for adding existing users to a hospital.
 * @returns {Promise<void>} - Promise that resolves when existing users are added to the hospital.
 */
const addExistingUser = async ({
  hospitalId,
  data,
}: IAddExistingUserQueryParams): Promise<void> => {
  const response = await axiosInstance.post(
    `/hospitals/${hospitalId}/add-users`,
    data
  );
  return response.data;
};

/**
 * Hook to use the addExistingUser mutation with React Query.
 *
 * @param {UseMutationOptions<void, unknown, IAddExistingUserQueryParams>} props - Options for the useMutation hook.
 * @returns {MutationResult<void>} - Mutation result for the addExistingUser mutation.
 */
export const useExistingAddUser = (
  props?: UseMutationOptions<void, unknown, IAddExistingUserQueryParams>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addExistingUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['hospital-users'],
      });
      queryClient.invalidateQueries({
        queryKey: ['hospital-details-summary'],
      });
    },
    ...props,
  });
};

type IAdminAddUserQueryParams = {
  data: Partial<IUsers>;
};

/**
 * Function to add an admin user.
 *
 * @param {IAdminAddUserQueryParams} params - Parameters for adding an admin user.
 * @returns {Promise<void>} - Promise that resolves when the admin user is added.
 */
const addAdminUser = async ({
  data,
}: IAdminAddUserQueryParams): Promise<void> => {
  const response = await axiosInstance.post('/users', data);
  return response.data;
};

/**
 * Hook to use the addAdminUser mutation with React Query.
 *
 * @param {UseMutationOptions<void, unknown, IAdminAddUserQueryParams>} props - Options for the useMutation hook.
 * @returns {MutationResult<void>} - Mutation result for the addAdminUser mutation.
 */
export const useAddAdminUser = (
  props?: UseMutationOptions<void, unknown, IAdminAddUserQueryParams>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addAdminUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['all-users'] });
    },
    ...props,
  });
};

type IResetPassword = {
  userId: string;
  data: {
    password: string;
  };
};

/**
 * Function to reset the password for a user.
 *
 * @param {IResetPassword} params - Parameters for resetting the password.
 * @returns {Promise<void>} - Promise that resolves when the password is reset.
 */
const resetPassword = async ({
  userId,
  data,
}: IResetPassword): Promise<void> => {
  const response = await axiosInstance.put(
    `/users/${userId}/reset-password`,
    data
  );
  return response.data;
};

/**
 * Hook to use the resetPassword mutation with React Query.
 *
 * @returns {MutationResult<void>} - Mutation result for the resetPassword mutation.
 */
export const useResetPassword = () => {
  return useMutation({
    mutationFn: resetPassword,
  });
};

type IGetUserAccountSummary = {
  userId: string;
};

/**
 * Function to get the account summary for a user.
 *
 * @param {IGetUserAccountSummary} params - Parameters for getting the account summary.
 * @returns {Promise<IAccountPageSummary>} - Promise that resolves to the account summary.
 */
const getUserAccountSummary = async ({
  userId,
}: IGetUserAccountSummary): Promise<IAccountPageSummary> => {
  const response = await axiosInstance.get(`/users/${userId}/account-summary`);
  return response.data;
};

/**
 * Hook to use the getUserAccountSummary query with React Query.
 *
 * @param {IGetUserAccountSummary} params - Parameters for getting the account summary.
 * @returns {QueryResult<IAccountPageSummary>} - Query result for the getUserAccountSummary query.
 */
export const useGetUserAccountSummary = ({
  userId,
}: IGetUserAccountSummary) => {
  return useQuery<IAccountPageSummary>({
    queryKey: ['user-account-summary', userId],
    queryFn: () => getUserAccountSummary({ userId }),
  });
};

type IGetRealtedUsers = {
  limit: number;
  page: number;
  userId: string;
  name?: string;
  role?: string;
  title?: string;
  hospitals?: string;
  sortBy?: string;
  sortTypes?: string;
};

type IGetRealtedUsersApiResponse = {
  count: number;
  rows: IRelatedUser[];
};

/**
 * Function to get related users for a user.
 *
 * @param {IGetRealtedUsers} params - Parameters for getting related users.
 * @returns {Promise<IGetRealtedUsersApiResponse>} - Promise that resolves to the related users.
 */
const getRealtedUsers = async ({
  userId,
  ...rest
}: IGetRealtedUsers): Promise<IGetRealtedUsersApiResponse> => {
  const response = await axiosInstance.get(`/users/${userId}/related-users`, {
    params: {
      ...rest,
    },
  });
  return response.data;
};

/**
 * Hook to use the getRelatedUsers query with React Query.
 *
 * @param {IGetRealtedUsers} props - Parameters for getting related users.
 * @returns {QueryResult<IGetRealtedUsersApiResponse>} - Query result for the getRelatedUsers query.
 */
export const useGetRelatedUsers = (props: IGetRealtedUsers) => {
  return useQuery<IGetRealtedUsersApiResponse>({
    queryKey: [
      'related-users',
      props,
      props?.limit,
      props?.page,
      props?.userId,
      props.sortBy,
      props.sortTypes,
    ],
    queryFn: () => getRealtedUsers(props),
  });
};

type IGetAllHospitalsForUser = {
  page: number;
  limit: number;
  hospitalName?: string;
  orStatus?: string;
  city?: string;
  state?: string;
  userId: string;
  sortBy?: string;
  sortTypes?: string;
};

/**
 * Function to get all hospitals for a user.
 *
 * @param {IGetAllHospitalsForUser} params - Parameters for getting all hospitals for a user.
 * @returns {Promise<IAccountsData>} - Promise that resolves to the list of all hospitals for the user.
 */
const getAllHospitalsForUser = async ({
  userId,
  ...rest
}: IGetAllHospitalsForUser): Promise<IHospitalsData> => {
  const response = await axiosInstance.get(`/users/${userId}/hospitals`, {
    params: {
      ...rest,
    },
  });
  return response.data;
};

/**
 * Hook to use the getAllHospitalsForUser query with React Query.
 *
 * @param {IGetAllHospitalsForUser} params - Parameters for getting all hospitals for a user.
 * @param {UseQueryOptions<IAccountsData>} props - Options for the useQuery hook.
 * @returns {QueryResult<IAccountsData>} - Query result for the getAllHospitalsForUser query.
 */
export const useGetAllHospitalsForUser = (
  params: IGetAllHospitalsForUser,
  props?: UseQueryOptions<IHospitalsData>
) => {
  return useQuery<IHospitalsData>({
    queryKey: [
      'all-user-hospital',
      params,
      params.userId,
      params.page,
      params.limit,
      params.hospitalName,
      params.orStatus,
      params.city,
      params.state,
      params.sortBy,
      params.sortTypes,
    ],
    queryFn: () => getAllHospitalsForUser(params),
    ...props,
  });
};

type IGetUserAccount = {
  userId: string;
};

type IGetUserAccountApiResponse = IAccount;

/**
 * Function to get the account information for a user.
 *
 * @param {IGetUserAccount} params - Parameters for getting the account information.
 * @returns {Promise<IGetUserAccountApiResponse>} - Promise that resolves to the account information.
 */
const getUserAccount = async ({
  userId,
}: IGetUserAccount): Promise<IGetUserAccountApiResponse> => {
  const response = await axiosInstance.get(`/users/${userId}/account-info`);
  return response.data;
};

/**
 * Hook to use the getUserAccount query with React Query.
 *
 * @param {IGetUserAccount} params - Parameters for getting the account information.
 * @returns {QueryResult<IGetUserAccountApiResponse>} - Query result for the getUserAccount query.
 */
export const useGetUserAccount = ({ userId }: IGetUserAccount) => {
  return useQuery<IGetUserAccountApiResponse>({
    queryKey: ['account-info', userId],
    queryFn: () => getUserAccount({ userId }),
  });
};

type IGetUserAccountInfo = {
  userId: string;
};

/**
 * Function to get the account information for a user.
 *
 * @param {IGetUserAccountInfo} params - Parameters for getting the account information.
 * @returns {Promise<IAddUpdateAccount>} - Promise that resolves to the account information.
 */
const getUserAccountInfo = async ({
  userId,
}: IGetUserAccountInfo): Promise<IAddUpdateAccount> => {
  const response = await axiosInstance.get(`/users/${userId}/account-info`);
  return response.data;
};

/**
 * Hook to use the getUserAccountInfo query with React Query.
 *
 * @param {IGetUserAccountInfo} params - Parameters for getting the account information.
 * @returns {QueryResult<IAddUpdateAccount>} - Query result for the getUserAccountInfo query.
 */
export const useGetUserAccountInfo = ({ userId }: IGetUserAccountInfo) => {
  return useQuery<IAddUpdateAccount>({
    queryKey: ['user-account-info', userId],
    queryFn: () => getUserAccountInfo({ userId }),
  });
};

type IResendValidationEmail = {
  userId: string;
};

/**
 * Function to reset the password for a user.
 *
 * @param {IResendValidationEmail} params - Parameters for resetting the password.
 * @returns {Promise<void>} - Promise that resolves when the password is reset.
 */
const resendValidationEmail = async ({
  userId,
}: IResendValidationEmail): Promise<void> => {
  const response = await axiosInstance.put(
    `/auth/${userId}/update-password-email`
  );
  return response.data;
};

/**
 * Hook to use the resetPassword mutation with React Query.
 *
 * @returns {MutationResult<void>} - Mutation result for the resetPassword mutation.
 */
export const useResendValidationEmail = () => {
  return useMutation({
    mutationFn: resendValidationEmail,
  });
};
