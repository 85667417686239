import { FC } from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { InputMask as MaskInput } from 'primereact/inputmask';
import TextError from './TextError';

type InputMaskProps = Extract<IFormControlProps, { control: 'inputMask' }>;

/**
 * InputMask component.
 *
 * @param {InputMaskProps} props - Component properties.
 */
const InputMask: FC<InputMaskProps> = (props) => {
  return (
    <Field name={props.name}>
      {({ field, meta }: FieldProps) => {
        const { label, name, type, mandatory, ...rest } = props;

        return (
          <div className="flex flex-col gap-2.5">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <label htmlFor={name} className="text-sm text-neutral-700">
                  {label}
                </label>

                {mandatory && (
                  <span className="-translate-y-1 text-xs text-red-500">*</span>
                )}
              </div>

              <ErrorMessage name={name} component={TextError} />
            </div>

            <MaskInput
              type={type}
              className={`${
                meta.error && 'p-invalid'
              } !placeholder:text-gray-500 !w-full !text-sm`}
              {...field}
              {...rest}
            />
          </div>
        );
      }}
    </Field>
  );
};

export default InputMask;
