import { useResetPassword } from 'api/services/users';
import FormControl from 'components/Formik/FormControl';
import { Form, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';
import { FC, useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

type IUpdatePasswordProps = {
  userId: string;
};

// Validation schema for password and confirmPassword
const validationSchema = z
  .object({
    password: z.string().min(4),
    confirmPassword: z.string().min(4),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  });

/**
 * UpdatePassword component allows users to update their password.
 * It uses Formik for form handling, zod for validation, and other hooks for state management.
 *
 * @param {object} IUpdatePasswordProps - Props for UpdatePassword component.
 * @param {string} IUpdatePasswordProps.userId - The user ID for whom the password is being updated.
 */
const UpdatePassword: FC<IUpdatePasswordProps> = ({ userId }) => {
  const [visible, setVisible] = useState(false);
  const toast = useRef<Toast | null>(null);

  type IUpdatePasswordValues = z.infer<typeof validationSchema>;

  // Initial form values
  const initialValues: IUpdatePasswordValues = {
    password: '',
    confirmPassword: '',
  };

  const {
    mutate: resetPassword,
    isPending,
    isError,
    isSuccess,
  } = useResetPassword();

  // Form submission handler
  const handleSubmit = (values: IUpdatePasswordValues) => {
    resetPassword({
      data: {
        password: values.password,
      },
      userId,
    });
  };

  useEffect(() => {
    // Show toast messages based on API response
    if (isError) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Error while updating password',
        life: 3000,
      });
    }
    if (isSuccess) {
      toast?.current?.show({
        severity: 'success',
        summary: 'Password updated successfully',
        life: 3000,
      });
      setVisible(false);
    }
  }, [isError, isSuccess]);

  return (
    <div>
      {/* Toast component for showing success/error messages */}
      <Toast ref={toast} />

      {/* Button to open the sidebar */}
      <Button
        icon="pi pi-key"
        label="Update Password"
        onClick={() => setVisible(true)}
        className="-translate-x-6 !scale-75"
      />

      {/* Sidebar component for password update form */}
      <Sidebar
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
        showCloseIcon={false}
        className="!relative w-96"
        header={
          <div className="flex w-full items-center border-b !px-0 pb-3">
            <span className="pi pi-key"></span>
            <span className="ml-4 text-lg font-semibold">Update Password</span>
            <button
              className="pi pi-times ml-auto cursor-pointer"
              onClick={() => setVisible(false)}
            ></button>
          </div>
        }
      >
        {/* Formik form for updating password */}
        <Formik
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(validationSchema)}
          onSubmit={(values) => handleSubmit(values)}
        >
          <Form id="updatePasswordForm">
            <div className="mb-6 space-y-4">
              {/* Form control for new password */}
              <FormControl
                control="input"
                type="password"
                name="password"
                label="New Password"
                placeholder="Enter New Password"
                mandatory={true}
              />

              {/* Form control for confirming new password */}
              <FormControl
                control="input"
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Re-Enter New Password"
                mandatory={true}
              />
            </div>
            {/* Submit button */}
            <div className="flex justify-end border-t pt-4">
              <Button
                label="Update"
                icon="pi pi-sync"
                size="small"
                form="updatePasswordForm"
                type="submit"
                loading={isPending}
              />
            </div>
          </Form>
        </Formik>
      </Sidebar>
    </div>
  );
};

export default UpdatePassword;
