import exinHealthLogo from 'assets/exin-health-logo.png';
import MenuItem from 'components/Layout/Sidebar/MenuItem.tsx';
import { useAuth } from 'hooks/useAuth';

type ISidebarMenuItem = {
  label: string;
  icon: string;
  href: string;
  isRootMenu?: boolean;
  children?: ISidebarMenuItem[];
};

type ISidebarMenu = (ISidebarMenuItem | undefined)[];

/**
 * Sidebar component represents the navigation sidebar of the application.
 * It dynamically renders menu items based on the user's role.
 *
 * @component
 */
const Sidebar = () => {
  const { userRole } = useAuth();

  const exinAdminMenu: ISidebarMenu = [
    {
      label: 'Dashboard',
      icon: 'pi pi-chart-bar',
      href: '/',
      children: [
        {
          label: 'Workflow Efficiency',
          icon: 'pi pi-chart-line',
          href: 'workflowefficiency',
        },
        {
          label: 'Infection Control',
          icon: 'pi pi-exclamation-triangle',
          href: 'infectioncontrol',
        },
        {
          label: 'Patient Safety',
          icon: 'pi pi-shield',
          href: 'patientsafety',
        },
        {
          label: 'Inventory',
          icon: 'pi pi-folder',
          href: 'inventorymanagement',
        },
        {
          label: 'Staff Well Being',
          icon: 'pi pi-globe',
          href: 'culture',
        },
      ],
    },
    {
      label: 'Procedures',
      icon: 'pi pi-video',
      href: '/procedures',
    },
    {
      label: 'Accounts',
      icon: 'pi pi-users',
      href: '/accounts',
    },
    {
      label: 'Admin',
      icon: 'pi pi-user',
      href: '/admin',
    },
    {
      label: 'Admin Operations',
      icon: 'pi pi-cog',
      href: '/operations',
      isRootMenu: true,
      children: [
        {
          label: 'Procedures',
          icon: 'pi pi-video',
          href: '/procedures-admin',
        },
        {
          label: 'Notifications',
          icon: 'pi pi-bell',
          href: '/notifications',
        },
      ],
    },
  ];

  const clientMenu: ISidebarMenu = [
    {
      label: 'Dashboard',
      icon: 'pi pi-chart-bar',
      href: '/',
      children: [
        {
          label: 'Workflow Efficiency',
          icon: 'pi pi-chart-line',
          href: 'workflowefficiency',
        },
        {
          label: 'Infection Control',
          icon: 'pi pi-exclamation-triangle',
          href: 'infectioncontrol',
        },
        {
          label: 'Patient Safety',
          icon: 'pi pi-shield',
          href: 'patientsafety',
        },
        {
          label: 'Inventory Management',
          icon: 'pi pi-folder',
          href: 'inventorymanagement',
        },
        {
          label: 'Culture',
          icon: 'pi pi-globe',
          href: 'culture',
        },
      ],
    },
    {
      label: 'Procedures',
      icon: 'pi pi-video',
      href: '/procedures',
    },
    {
      label: 'Account Details',
      icon: 'pi pi-sitemap',
      href: '/account-details',
    },
    userRole === 'hospitalITAdmin'
      ? {
          label: 'Admin',
          icon: 'pi pi-user',
          href: '/admin',
        }
      : undefined,
  ];

  return (
    // Sidebar container with a fixed width and padding
    <div className="h-screen min-w-[248px] px-4 py-5">
      {/* Logo for the sidebar */}
      <img src={exinHealthLogo} alt="Exin Health Logo" className="" />

      <div>
        {/* Navigation menu with dynamic menu items based on user role */}
        <ul className="mt-12 flex flex-col gap-3">
          {(userRole === 'exinUser' ? exinAdminMenu : clientMenu)
            ?.filter((item) => item !== undefined)
            .map((item) => <MenuItem item={item!} key={item?.href} />)}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
