import { FC } from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import TextError from './TextError';

type TextAreaProps = Extract<IFormControlProps, { control: 'textarea' }>;

/**
 * TextArea component.
 *
 * @param {TextAreaProps} props - Component properties.
 */
const TextArea: FC<TextAreaProps> = (props) => {
  return (
    <Field name={props.name}>
      {({ field, meta }: FieldProps) => {
        const { label, name, mandatory, ...rest } = props;

        return (
          <div className="flex flex-col gap-2.5">
            <div className="items-cener flex justify-between">
              <div className="flex items-center gap-1">
                <label htmlFor={name} className="text-sm text-neutral-700">
                  {label}
                </label>

                {mandatory && (
                  <span className="-translate-y-1 text-xs text-red-500">*</span>
                )}
              </div>

              <ErrorMessage name={name} component={TextError} />
            </div>

            <InputTextarea
              className={`${
                meta.error && 'p-invalid'
              } !placeholder:text-gray-500 !text-sm`}
              {...field}
              {...rest}
            />
          </div>
        );
      }}
    </Field>
  );
};

export default TextArea;
