import ProfileSidebar from 'components/ProfileSidebar';
import { useAuth } from 'hooks/useAuth';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Acibadem from 'assets/acibadem.jpeg';

/**
 * Header component represents the top header of the application.
 * It includes user information, avatar, and a logout button.
 *
 * @component
 */
const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userName, userEmail, logout } = useAuth();
  const userIcon = useRef<OverlayPanel | null>(null);
  const [visible, setVisible] = useState(false);

  return (
    <header className="flex items-center justify-end gap-10 px-4 py-3.5">
      {/* Avatar for user icon */}
      <div
        style={{ width: '120px', overflow: 'hidden' }}
        className="flex items-center justify-start gap-2.5"
      >
        <img
          src={Acibadem}
          alt="Logo"
          style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
        />
      </div>
      <Avatar
        className="cursor-pointer"
        style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
        shape="circle"
        label={userName?.charAt(0)}
        aria-controls="popup_menu_right"
        aria-haspopup
        onClick={(event) => userIcon.current?.toggle(event)}
      />

      {/* OverlayPanel for user information and actions */}
      <OverlayPanel ref={userIcon}>
        <div className="flex flex-col">
          {/* Avatar within OverlayPanel */}
          <Avatar
            style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
            shape="circle"
            label={userName?.charAt(0)}
          />

          {/* User information */}
          <div className="mt-3">
            <p className="text-sm font-semibold">{userName}</p>
            <p className="mt-1 text-xs text-gray-400">{userEmail}</p>
          </div>

          {/* ProfileSidebar component for user profile settings */}
          <ProfileSidebar />

          {/* Logout section */}
          <div className="mt-4 border-t pt-4 text-red-500">
            {/* Logout button */}
            <button
              className="flex cursor-pointer items-center gap-3 px-2"
              onClick={() => setVisible(true)}
            >
              <span className="pi pi-sign-out"></span>
              <p className="text-sm font-semibold leading-tight">
                {t('general.button.logout')}
              </p>
            </button>

            {/* Logout confirmation dialog */}
            <Dialog
              visible={visible}
              closable={false}
              onHide={() => setVisible(false)}
              headerStyle={{ padding: '14px' }}
            >
              <h2 className="mb-4 border-b pb-4 font-semibold">
                {t('general.button.confirm.logout')}
              </h2>
              <p className="mb-6">{t('general.button.confirm.logout.title')}</p>

              {/* Confirm and Cancel buttons */}
              <div className="flex w-full justify-end">
                <Button
                  label={t('general.button.cancel')}
                  size="small"
                  className="p-button-text ml-5 scale-90"
                  onClick={() => setVisible(false)}
                />
                <Button
                  label={t('general.button.confirm')}
                  size="small"
                  className="scale-90"
                  onClick={() => {
                    navigate('/');
                    logout();
                  }}
                />
              </div>
            </Dialog>
          </div>
        </div>
      </OverlayPanel>
    </header>
  );
};

export default Header;
