import { ErrorMessage, useField } from 'formik';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import TextError from './TextError';

type MyDropzoneProps = Extract<IFormControlProps, { control: 'dropzone' }>;

function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

/**
 * MyDropzone component.
 *
 * @param {MyDropzoneProps} props - Component properties.
 */
const MyDropzone: FC<MyDropzoneProps> = (props) => {
  const [field, , helpers] = useField(props.name);
  const [warning, setWarning] = useState<string>('');

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setWarning('');

      const file = acceptedFiles[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          if (event.target?.result) {
            const buffer = event.target.result as ArrayBuffer;
            const base64String = arrayBufferToBase64(buffer);
            const imageDataUrl = `data:image/png;base64,${base64String}`;
            helpers.setValue(imageDataUrl);
          }
        };
        reader.readAsArrayBuffer(file);
      }
    },
    [helpers]
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/*': ['.png', '.svg'],
    },
    maxSize: props.maxSize,
  });

  useEffect(() => {
    if (fileRejections.length > 0) {
      const errorCode = fileRejections?.[0]?.errors?.[0]?.code;

      if (errorCode === 'file-too-large') {
        setWarning(
          `File size should be less than ${props.maxSize / 1024 / 1024} MB`
        );
      } else if (errorCode === 'file-invalid-type') {
        setWarning('File type not supported');
      }
    }
  }, [fileRejections]);

  return (
    <div className="flex flex-col gap-2.5">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1">
          <label htmlFor={props.name} className="text-sm text-neutral-700">
            {props.label}
          </label>

          {props.mandatory && (
            <span className="-translate-y-1 text-xs text-red-500">*</span>
          )}
        </div>

        <ErrorMessage name={props.name} component={TextError} />
      </div>

      {/* Drop zone area */}
      <div
        {...getRootProps()}
        className="flex cursor-pointer flex-col items-center justify-center border border-dashed py-8"
      >
        <input {...getInputProps()} name="image" />

        {/* Placeholder icon */}
        <div className="flex w-max items-center justify-center rounded-full border p-3">
          <span className="pi pi-user !text-3xl"></span>
        </div>

        {/* Instructions for uploading */}
        <p className="mt-3 text-sm font-semibold leading-tight text-zinc-600">
          Drag logo to upload
        </p>
        <p className="mt-1 text-xs font-normal leading-none text-gray-500">
          or click to Upload (max 4 mb)
        </p>
        {warning && (
          <p className="mt-1 text-xs font-normal leading-none text-red-500">
            {warning}
          </p>
        )}
      </div>

      {/* Display the preview of the uploaded image */}
      {field.value && (
        <div className="relative max-w-max rounded-lg border p-1">
          <img
            src={field.value}
            alt="Preview"
            style={{ maxWidth: '100px', maxHeight: '100px' }}
          />
          {/* a cross button to remove this image */}
          <button
            type="button"
            className="absolute -right-2 -top-2 rounded-full border border-gray-300 bg-white px-1 text-sm"
            onClick={() => helpers.setValue('')}
          >
            X
          </button>
        </div>
      )}
    </div>
  );
};

export default MyDropzone;
