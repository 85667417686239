import { FC } from 'react';
import Input from './Input';
import TextArea from './TextArea';
import Select from './Select';
import MultiSelect from './MultiSelect';
import PhoneNumber from './PhoneNumber';
import MyDropzone from './MyDropzone';
import InputMask from './InputMask';
import CustomCalendar from './CustomCalendar';
import CheckboxInput from './Checkbox';

/**
 * FormControl component.
 *
 * @param {IFormControlProps} props - Component properties.
 */
const FormControl: FC<IFormControlProps> = (props) => {
  const { control } = props;

  switch (control) {
    case 'input':
      return <Input {...props} />;
    case 'textarea':
      return <TextArea {...props} />;
    case 'select':
      return <Select {...props} />;
    case 'multiSelect':
      return <MultiSelect {...props} />;
    case 'phoneNumber':
      return <PhoneNumber {...props} />;
    case 'dropzone':
      return <MyDropzone {...props} />;
    case 'inputMask':
      return <InputMask {...props} />;
    case 'calendar':
      return <CustomCalendar {...props} />;
    case 'checkbox':
      return <CheckboxInput {...props} />;
    default:
      return null;
  }
};

export default FormControl;
