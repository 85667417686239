import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import { useEffect } from 'react';

/**
 * Custom hook for handling authentication using Keycloak.
 *
 * @returns {Object} An object containing user authentication information.
 * @property {string | undefined} userId - The unique identifier of the user.
 * @property {'exinUser' | 'hospitalUser' | 'hospitalITAdmin' | 'others' | undefined} userRole - The role of the user.
 * @property {string | undefined} userName - The name of the user.
 * @property {string | undefined} userEmail - The email of the user.
 * @property {string | undefined} token - The authentication token.
 * @property {string | undefined} refreshToken - The refresh token.
 * @property {boolean | undefined} isAuthenticated - Indicates whether the user is authenticated.
 * @property {() => boolean | undefined} isTokenExpired - Function to check if the authentication token is expired.
 * @property {() => Promise<void> | undefined} logout - Function to logout the user.
 */
export const useAuth = () => {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    const handleLoginEvent = () => {
      const lastLoginTime = moment().format('MM/DD/YY');
      localStorage.setItem('lastLoginTime', lastLoginTime);
      // You can also send this timestamp to your backend to store it in a database
    };

    const loginListener = () => {
      if (keycloak.authenticated) {
        handleLoginEvent();
        // Remove the event listener after handling the login event
        keycloak.onAuthSuccess = undefined;
      }
    };

    if (!keycloak.authenticated) {
      // Attach an event listener to handle successful login events
      keycloak.onAuthSuccess = loginListener;

      // Clean up the event listener when the component unmounts
      return () => {
        keycloak.onAuthSuccess = undefined;
      };
    } else {
      // If the user is already authenticated, set the last login time
      handleLoginEvent();
    }
  }, [keycloak]);

  // Extract user roles from the Keycloak token
  const keycloakRoles = keycloak?.tokenParsed?.roles;

  // Determine the user role based on Keycloak roles
  const userRole:
    | 'exinUser'
    | 'hospitalUser'
    | 'hospitalITAdmin'
    | 'others'
    | undefined = keycloakRoles?.includes('exin_health_admin')
    ? 'exinUser'
    : keycloakRoles?.includes('hospital_user')
      ? 'hospitalUser'
      : keycloakRoles?.includes('hospital_it_admin')
        ? 'hospitalITAdmin'
        : keycloakRoles?.includes('others')
          ? 'others'
          : undefined;

  // Extract user information from the Keycloak token
  const userId: string | undefined = keycloak?.tokenParsed?.userId;
  const userName: string | undefined = keycloak?.tokenParsed?.name;
  const userEmail: string | undefined = keycloak?.tokenParsed?.email;
  const token: string | undefined = keycloak?.token;
  const refreshToken: string | undefined = keycloak?.refreshToken;
  const isAuthenticated: boolean | undefined = keycloak?.authenticated;
  const isTokenExpired: (() => boolean) | undefined = keycloak?.isTokenExpired;
  const logout: (() => Promise<void>) | undefined = keycloak?.logout;

  // Return an object containing user authentication information
  return {
    userId,
    userRole,
    userName,
    userEmail,
    token,
    refreshToken,
    isAuthenticated,
    isTokenExpired,
    logout,
  };
};
