import { BeatLoader } from 'react-spinners';

/**
 * Fallback component.
 *
 * A component that displays a loading indicator using BeatLoader.
 */
const Fallback = () => {
  // Return a JSX structure for the loading fallback
  return (
    <div className="flex min-h-screen min-w-full flex-col items-center justify-center gap-4">
      {/* Display a loading message */}
      <div className="text-xl font-bold">Loading...</div>

      {/* Use the BeatLoader component for a spinning loading animation */}
      <BeatLoader />
    </div>
  );
};

export default Fallback;
