import { useGetUser } from 'api/services/users';
import { useAuth } from 'hooks/useAuth';
import { Sidebar } from 'primereact/sidebar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateProfile from './UpdateProfile';
import UpdatePassword from './UpdatePassword';
import { Skeleton } from 'primereact/skeleton';

/**
 * ProfileSidebar component for displaying user profile information.
 *
 * @component
 *
 */
const ProfileSidebar = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { userId } = useAuth();

  const { data, isLoading } = useGetUser({ userId: userId!, enabled: visible });

  return (
    <div>
      <button
        className="mt-4 flex cursor-pointer items-center gap-2 text-zinc-600"
        onClick={() => setVisible(true)}
      >
        <span className="pi pi-cog"></span>
        <p className="text-sm font-semibold leading-tight">
          {t('genereal.button.setting')}
        </p>
      </button>

      <Sidebar
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
        showCloseIcon={false}
        className="!relative w-96"
        header={
          <div className="flex w-full items-center justify-between border-b !px-0">
            <div className="flex items-center pb-3">
              <span className="pi pi-cog"></span>
              <span className="ml-4 text-lg font-semibold">
                {t('genereal.button.setting')}
              </span>
            </div>

            <button
              className="pi pi-times -translate-y-1 cursor-pointer"
              onClick={() => setVisible(false)}
            ></button>
          </div>
        }
      >
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <div className="text-lg font-semibold leading-tight text-zinc-600">
              Account info
            </div>

            {isLoading ? (
              <Skeleton
                width="7rem"
                height="2.7rem"
                className="-translate-x-5 translate-y-1"
              />
            ) : (
              <>{data?.id && <UpdateProfile userData={data} />}</>
            )}
          </div>

          {isLoading ? (
            <div className="flex items-center gap-3">
              <Skeleton className="!h-14 !w-14" />
              <Skeleton className="!h-4 !w-24 !rounded-full" />
            </div>
          ) : (
            <div className="flex items-center gap-3">
              {data?.profile?.image ? (
                <img
                  className="h-14 w-14 rounded-full"
                  alt=""
                  src={`data:image/png;base64,${data?.profile?.image}`}
                />
              ) : (
                <div className="inline-flex h-14 w-14 flex-col items-center justify-center rounded-md bg-zinc-200">
                  <div className="text-3xl font-normal leading-loose text-zinc-600">
                    {data?.profile?.firstName?.charAt(0)}
                    {data?.profile?.lastName?.charAt(0)}
                  </div>
                </div>
              )}
              <div className="text-sm font-semibold leading-none text-zinc-600">
                Profile Picture
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="inline-flex flex-col items-start justify-start gap-2">
              <Skeleton className="!h-3 !w-16 !rounded-full" />
              <Skeleton className="!h-3 !w-28 !rounded-full" />
            </div>
          ) : (
            <div className="inline-flex flex-col items-start justify-start gap-2">
              <div className="text-xs font-normal leading-none text-gray-500">
                First Name
              </div>
              <div className="text-sm font-semibold leading-none text-zinc-600">
                {data?.profile?.firstName}
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="inline-flex flex-col items-start justify-start gap-2">
              <Skeleton className="!h-3 !w-16 !rounded-full" />
              <Skeleton className="!h-3 !w-28 !rounded-full" />
            </div>
          ) : (
            <div className="inline-flex flex-col items-start justify-start gap-2">
              <div className="text-xs font-normal leading-none text-gray-500">
                Last Name
              </div>

              <div className="text-sm font-semibold leading-none text-zinc-600">
                {data?.profile?.lastName}
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="inline-flex flex-col items-start justify-start gap-2">
              <Skeleton className="!h-3 !w-16 !rounded-full" />
              <Skeleton className="!h-3 !w-28 !rounded-full" />
            </div>
          ) : (
            <div className="inline-flex flex-col items-start justify-start gap-2">
              <div className="text-xs font-normal leading-none text-gray-500">
                Email
              </div>

              <div className="text-sm font-semibold leading-none text-zinc-600">
                {data?.profile?.email}
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="inline-flex flex-col items-start justify-start gap-2">
              <Skeleton className="!h-3 !w-16 !rounded-full" />
              <Skeleton className="!h-3 !w-28 !rounded-full" />
            </div>
          ) : (
            <div className="inline-flex flex-col items-start justify-start gap-2">
              <div className="text-xs font-normal leading-none text-gray-500">
                Phone
              </div>

              <div className="text-sm font-semibold leading-none text-zinc-600">
                +{data?.profile?.countryCode} {data?.profile?.phone}
              </div>
            </div>
          )}
        </div>

        {isLoading ? (
          <Skeleton className="mt-6 !h-10 !w-36" />
        ) : (
          <>
            {data?.id && (
              <div className="mt-6">
                <UpdatePassword userId={data?.id} />
              </div>
            )}
          </>
        )}
      </Sidebar>
    </div>
  );
};

export default ProfileSidebar;
