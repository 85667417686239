import { FC } from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import TextError from './TextError';
import { MultiSelect as PrimeReactMultiSelect } from 'primereact/multiselect';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';

type MultiSelectProps = Extract<IFormControlProps, { control: 'multiSelect' }>;

/**
 * MultiSelect component.
 *
 * @param {MultiSelectProps} props - Component properties.
 */
const MultiSelect: FC<MultiSelectProps> = (props) => {
  return (
    <Field name={props.name}>
      {({ field, meta, form }: FieldProps) => {
        const {
          isClear = false,
          label,
          name,
          options,
          mandatory,
          ...rest
        } = props;

        return (
          <div className="flex flex-col gap-2.5">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <label
                  htmlFor={name}
                  className="text-sm font-bold text-neutral-700"
                >
                  {label}
                </label>

                {mandatory && (
                  <span className="-translate-y-1 text-xs text-red-500">*</span>
                )}
              </div>
              {/* Button for clear the multiselect */}
              <div>
                {isClear && meta.value && meta.value.length > 0 && (
                  <Button
                    type="button"
                    label="Clear"
                    outlined
                    className="!border-none !p-0 !text-xs !font-normal"
                    onClick={() => form.setFieldValue(name, [])}
                  />
                )}
              </div>
              <ErrorMessage name={name} component={TextError} />
            </div>

            <PrimeReactMultiSelect
              options={options}
              optionLabel="label"
              className={`${meta.error && 'p-invalid'}`}
              {...field}
              {...rest}
              value={meta.value && meta.value.length > 0 ? meta.value : null}
              onChange={(e) => {
                form.setFieldValue(name, e.value);
              }}
            />

            {meta.value && meta.value.length > 0 && (
              <div className="mb-4 flex flex-wrap gap-1">
                {meta.value.map((item: string) => (
                  <Chip
                    key={item}
                    label={
                      options?.find((option) => option.value === item)?.label
                    }
                    onClick={() =>
                      form.setFieldValue(
                        name,
                        meta.value.filter((option: string) => option !== item)
                      )
                    }
                    removable
                  />
                ))}
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default MultiSelect;
