import Header from 'components/Layout/Header.tsx';
import Sidebar from 'components/Layout/Sidebar/Sidebar.tsx';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { history } from 'route/history';

/**
 * Layout component represents the overall structure of the application.
 * It includes a sidebar, header, and a main content area.
 *
 * @component
 */
const Layout = () => {
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <div className=" flex h-screen w-full bg-primary text-primary-text">
      {/* Sidebar component */}
      <Sidebar />

      {/* Main content area, including header and a scrollable main section */}
      <div className="flex grow flex-col">
        {/* Header component */}
        <Header />

        {/* Main section with a scrollable content area */}
        <main className="grow overflow-y-scroll rounded-tl-2xl bg-white px-8 py-7">
          {/* Route outlet for rendering nested routes */}
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
