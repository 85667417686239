import { useState } from 'react';
import { NavLink } from 'react-router-dom';

/**
 * MenuItem component represents a single menu item in the navigation menu.
 * It can have children, and clicking on it toggles the visibility of its children.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Object} props.item - The menu item object with properties like label, href, icon, and children.
 */
const MenuItem = ({ item }: { item: IMenuItem }) => {
  const [visible, setVisible] = useState(false);

  return (
    <li>
      {/* NavLink for navigation with conditional styles based on isActive and visibility */}
      <NavLink
        to={
          item.isRootMenu !== undefined &&
          item.isRootMenu === true &&
          item.children
            ? `${item.href}${item.children[0].href}`
            : item?.href
        }
        className={({ isActive }) =>
          `flex items-center gap-4 rounded-lg px-2.5 py-2 ${
            isActive
              ? 'border border-emerald-500 bg-emerald-600 font-semibold'
              : 'hover:bg-emerald-600'
          } ${item?.children?.length && 'p-accordion'}`
        }
        onClick={() => setVisible((prev) => !prev)}
      >
        {/* Icon for the menu item */}
        <span className={`${item?.icon}`}></span>
        <span>{item?.label}</span>

        {/* Dropdown icon for menu items with children */}
        {item?.children && item?.children?.length > 0 && (
          <i
            className={`pi ml-auto ${
              visible ? 'pi-angle-down' : 'pi-angle-right'
            }`}
          ></i>
        )}
      </NavLink>

      {/* Display children menu items if they exist and visibility is true */}
      {item?.children && item?.children?.length > 0 && visible && (
        <ul className="mt-1 flex flex-col gap-1 pl-6">
          {/* Mapping through children to render nested menu items */}
          {item.children.map((child) => (
            <li key={child?.href}>
              {/* NavLink for nested menu items with conditional styles based on isActive */}
              <NavLink
                to={`${item?.href}${child?.href}`}
                className={({ isActive }) =>
                  `flex items-center gap-4 rounded-lg px-2.5 py-2 ${
                    isActive
                      ? 'border border-emerald-500 bg-emerald-600 font-semibold'
                      : 'hover:bg-emerald-600'
                  }`
                }
              >
                {/* Icon for nested menu item */}
                <span className={`${child?.icon}`}></span>
                <span>{child?.label}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default MenuItem;
