import Keycloak from 'keycloak-js';
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from './Constants';

console.log(`clientId: ${KEYCLOAK_CLIENT_ID}`);
console.log(`realm: ${KEYCLOAK_REALM}`);
console.log(`url: ${KEYCLOAK_URL}`);

const keycloakConfig = {
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
};

export const keycloak = new Keycloak(keycloakConfig);
