import { FC } from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import TextError from './TextError';

type CheckboxProps = Extract<IFormControlProps, { control: 'checkbox' }>;

/**
 * CheckboxInput component.
 *
 * @param {CheckboxProps} props - Component properties.
 */
const CheckboxInput: FC<CheckboxProps> = (props) => {
  return (
    <Field name={props?.name}>
      {/* Render prop for Field component */}
      {({ field }: FieldProps) => {
        // Destructure props to extract label, name, and rest of the properties
        const { label, name, ...rest } = props;

        // Return JSX structure for the CheckboxInput
        return (
          <div className="flex gap-2.5">
            {/* Checkbox component */}
            <Checkbox
              inputId={name}
              checked={field.value}
              onChange={(e) => field.onChange(e.checked)}
              {...rest}
            />
            <div className="flex items-center gap-1">
              {/* Label for the checkbox */}
              <label htmlFor={name} className="text-sm text-neutral-700">
                {label}
              </label>
            </div>
            {/* Error message for the checkbox */}
            <ErrorMessage name={name} component={TextError} />
          </div>
        );
      }}
    </Field>
  );
};

export default CheckboxInput;
