import { createBrowserRouter } from 'react-router-dom';
import Layout from 'components/Layout/Layout.tsx';
import ErrorPage from 'pages/SharedPages/ErrorPage';
import { Suspense, lazy } from 'react';
import Fallback from 'components/Fallback';
//import WorkflowEfficiency from 'pages/SharedPages/WorkflowefficiencyPage';
// Lazy loading the components for different routes
const ClientUserAdmin = lazy(() => import('pages/ClientUser/ClientUserAdmin'));
const ClientUserDashboard = lazy(
  () => import('pages/ClientUser/ClientUserDashboard')
);
const AccountDetails = lazy(() => import('pages/ClientUser/AccountDetails'));
const HospitalDetails = lazy(() => import('pages/ClientUser/HospitalDetails'));
const EdgeBoxDetails = lazy(() => import('pages/ExinUser/EdgeBoxDetails'));
const UserDetails = lazy(() => import('pages/ClientUser/UserDetails'));
const AccountInfo = lazy(() => import('pages/ClientUser/AccountInfo'));
const Procedures = lazy(() => import('pages/SharedPages/Procedures'));
const WorkflowEfficiency = lazy(
  () => import('pages/SharedPages/WorkflowEfficiency')
);
const InfectionControl = lazy(
  () => import('pages/SharedPages/InfectionControlPage')
);
const PatientSafety = lazy(() => import('pages/SharedPages/PatientSafetyPage'));
const InventoryManagement = lazy(
  () => import('pages/SharedPages/InventoryMngmtPage')
);
const Culture = lazy(() => import('pages/SharedPages/StaffWellBeing'));
const ProcedureDetailsPage = lazy(
  () => import('pages/SharedPages/ProcedureDetailsPage')
);

// Creating the router for client user
export const clientUserRouter = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Fallback />}>
            <ClientUserDashboard />
          </Suspense>
        ),
      },
      {
        path: 'workflowefficiency',
        element: (
          <Suspense fallback={<Fallback />}>
            <WorkflowEfficiency />
          </Suspense>
        ),
      },
      {
        path: 'infectioncontrol',
        element: (
          <Suspense fallback={<Fallback />}>
            <InfectionControl />
          </Suspense>
        ),
      },
      {
        path: 'patientsafety',
        element: (
          <Suspense fallback={<Fallback />}>
            <PatientSafety />
          </Suspense>
        ),
      },
      {
        path: 'inventorymanagement',
        element: (
          <Suspense fallback={<Fallback />}>
            <InventoryManagement />
          </Suspense>
        ),
      },
      {
        path: 'culture',
        element: (
          <Suspense fallback={<Fallback />}>
            <Culture />
          </Suspense>
        ),
      },
      {
        path: '/procedures',
        element: (
          <Suspense fallback={<Fallback />}>
            <Procedures />
          </Suspense>
        ),
      },
      {
        path: 'procedures/details/:procedureId',
        element: (
          <Suspense fallback={<Fallback />}>
            <ProcedureDetailsPage />
          </Suspense>
        ),
      },
      {
        path: '/account-details',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Fallback />}>
                <AccountDetails />
              </Suspense>
            ),
          },
          {
            path: '/account-details/:hospitalId',
            element: (
              <Suspense fallback={<Fallback />}>
                <HospitalDetails />
              </Suspense>
            ),
          },
          {
            path: '/account-details/account-info',
            element: (
              <Suspense fallback={<Fallback />}>
                <AccountInfo />
              </Suspense>
            ),
          },
          {
            path: '/account-details/:hospitalId/:edgeboxId',
            element: (
              <Suspense fallback={<Fallback />}>
                <EdgeBoxDetails />
              </Suspense>
            ),
          },
          {
            path: '/account-details/user-details/:hospitalId/:userId',
            element: (
              <Suspense fallback={<Fallback />}>
                <UserDetails />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: '/admin',
        element: (
          <Suspense fallback={<Fallback />}>
            <ClientUserAdmin />
          </Suspense>
        ),
      },
    ],
  },
]);
