/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import TextError from './TextError';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';

type CalendarProps = Extract<IFormControlProps, { control: 'calendar' }>;

/**
 * CustomCalendar component.
 *
 * @param {CalendarProps} props - Component properties.
 */
const CustomCalendar: FC<CalendarProps> = (props) => {
  return (
    <Field name={props?.name}>
      {({ field, meta, form }: FieldProps) => {
        // Destructure props to extract label, name, mandatory, and rest of the properties
        const { label, name, mandatory, ...rest } = props;

        // Determine the selected date from meta or set to null
        const selectedDate = meta?.value !== undefined ? meta.value : null;

        return (
          <div className="flex flex-col gap-2.5 !text-sm">
            <div className="flex items-center justify-between">
              <div className="flex w-full items-center gap-1">
                <div className="flex w-full items-center justify-between">
                  {/* Label for the calendar */}
                  <label
                    htmlFor={name}
                    className="text-sm font-bold text-neutral-700"
                  >
                    {label}
                  </label>
                  {/* Button for clear the calendar */}
                  {selectedDate && meta.value && meta.value.length > 0 && (
                    <Button
                      type="button"
                      label="Clear"
                      outlined
                      className="!border-none !p-0 !text-xs !font-normal"
                      onClick={() => form.setFieldValue(name, '')}
                    />
                  )}
                </div>
                {/* Display a red asterisk for mandatory fields */}
                {mandatory && (
                  <span className="-translate-y-1 text-xs text-red-500">*</span>
                )}
              </div>

              <ErrorMessage name={name} component={TextError} />
            </div>

            <Calendar
              {...field}
              {...rest}
              showIcon
              value={selectedDate}
              onChange={(e: any) => form.setFieldValue(name, e.value)}
              className={`!text-sm ${meta.error && 'p-invalid'}`}
            />
          </div>
        );
      }}
    </Field>
  );
};

export default CustomCalendar;
