// Import necessary css, components & hooks
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { exinUserRouter } from 'route/ExinUserRoutes';
import { clientUserRouter } from 'route/ClientUserRoutes';
import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import en from 'i18/en-US/translations.json';
import Fallback from 'components/Fallback';

/**
 * App component.
 *
 * Component for managing app
 *
 * @component
 * @returns {JSX.Element} - The rendered App component.
 */

const App = () => {
  // Destructuring userRole and isAuthenticated from useAuth hook.
  const { userRole, isAuthenticated } = useAuth();
  // useEffect to initialize i18n
  useEffect(() => {
    i18n.use(initReactI18next).init({
      lng: 'en',
      resources: {
        en: { translations: { ...en } },
      },
      fallbackLng: 'en-US',
      keySeparator: false,
      ns: ['translations'],
      defaultNS: 'translations',
      interpolation: { escapeValue: false },
      react: { useSuspense: true },
    });
  }, []);

  return (
    // Conditional rendering based on isAuthenticated
    <>
      {isAuthenticated ? (
        <I18nextProvider i18n={i18n}>
          <RouterProvider
            router={userRole === 'exinUser' ? exinUserRouter : clientUserRouter}
          />
        </I18nextProvider>
      ) : (
        // Render Fallback component if not authenticated
        <Fallback />
      )}
    </>
  );
};

export default App;
