import { FC, PropsWithChildren } from 'react';

/**
 * TextError component.
 *
 * @param {PropsWithChildren} props - Component properties.
 */
const TextError: FC<PropsWithChildren> = ({ children }) => {
  return <div className="text-xs text-red-500">{children}</div>;
};

export default TextError;
