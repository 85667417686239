import {
  useRouteError,
  isRouteErrorResponse,
  useNavigate,
} from 'react-router-dom';
import { Button } from 'primereact/button';

/**
 * ErrorPage component is responsible for rendering the error page.
 * It includes a message about the error and a "Go Back" button.
 * @returns JSX.Element
 */
const ErrorPage: React.FC = () => {
  // Define the navigate function
  const navigate = useNavigate();

  // Get the route error
  const error = useRouteError();
  let errorMessage: string;

  // Determine the error message based on the type of error
  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    errorMessage = error.data || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = 'Unknown error';
  }

  // Render the error message and the "Go Back" button
  return (
    <div
      id="error-page"
      className="flex h-screen flex-col items-center justify-center gap-8"
    >
      <h1 className="text-4xl font-bold">Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p className="text-slate-400">
        <i>{errorMessage}</i>
      </p>
      <Button size="small" onClick={() => navigate(-1)}>
        Go Back
      </Button>
    </div>
  );
};

export default ErrorPage;
