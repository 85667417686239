const orIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4476 11.1455H2.55172C2.3998 11.1455 2.25406 11.2059 2.14659 11.3134C2.03912 11.4208 1.97884 11.5666 1.97884 11.7185V14.0102C1.97884 14.1621 2.03912 14.3078 2.14659 14.4151C2.25406 14.5226 2.3998 14.583 2.55172 14.583H7.70791V18.0204H6.56217C6.35745 18.0204 6.16828 18.1296 6.06596 18.3069C5.96367 18.4841 5.96367 18.7025 6.06596 18.8798C6.16825 19.0572 6.35744 19.1664 6.56217 19.1664H13.4369C13.6416 19.1664 13.8307 19.0572 13.9331 18.8798C14.0353 18.7025 14.0353 18.4841 13.9331 18.3069C13.8308 18.1296 13.6416 18.0204 13.4369 18.0204H12.2911V14.583H17.4473C17.5992 14.583 17.745 14.5226 17.8524 14.4151C17.9599 14.3078 18.0202 14.1621 18.0202 14.0102V11.7185C18.0202 11.5666 17.9599 11.4208 17.8524 11.3134C17.745 11.2059 17.5995 11.1455 17.4476 11.1455ZM11.1456 18.0205H8.8541V14.5831H11.1458L11.1456 18.0205ZM16.8745 13.437H3.1248V12.2913H16.8749L16.8745 13.437Z"
      fill="currentColor"
    />
    <path
      d="M18.5934 0.833008H14.0103C13.8055 0.833008 13.6164 0.942201 13.514 1.11959C13.4116 1.29683 13.4116 1.51522 13.514 1.69248C13.6163 1.86972 13.8055 1.97892 14.0103 1.97892H15.729L15.7289 3.46048L13.773 5.41633H11.7186C11.5667 5.41633 11.421 5.47675 11.3135 5.58422C11.206 5.69155 11.1456 5.83729 11.1456 5.98921V8.28088C11.1456 8.4328 11.206 8.57854 11.3135 8.68601C11.421 8.79348 11.5667 8.8539 11.7186 8.8539H16.3021C16.4542 8.8539 16.5996 8.79348 16.7071 8.68601C16.8146 8.57854 16.875 8.4328 16.875 8.28088V5.98921C16.875 5.83728 16.8146 5.69155 16.7071 5.58422C16.5996 5.47675 16.454 5.41633 16.302 5.41633H15.3935L16.707 4.10283C16.8147 3.9955 16.8751 3.84976 16.875 3.6977V1.97892H18.5938C18.7985 1.97892 18.9875 1.86972 19.0898 1.69248C19.1921 1.51523 19.1921 1.29685 19.0898 1.11959C18.9876 0.942201 18.7981 0.833008 18.5934 0.833008ZM15.7289 7.70806H12.2914V6.56231H15.7289V7.70806Z"
      fill="currentColor"
    />
    <path
      d="M3.12481 3.6977C3.12467 3.84976 3.18509 3.9955 3.29285 4.10283L4.60635 5.41633H3.69783C3.54576 5.41633 3.40017 5.47675 3.2927 5.58422C3.18523 5.69155 3.12481 5.83729 3.12481 5.98921V8.28088C3.12481 8.4328 3.18523 8.57854 3.2927 8.68601C3.40017 8.79348 3.54576 8.8539 3.69783 8.8539H8.28132C8.43324 8.8539 8.57883 8.79348 8.6863 8.68601C8.79377 8.57854 8.85419 8.4328 8.85419 8.28088V5.98921C8.85419 5.83728 8.79377 5.69155 8.6863 5.58422C8.57883 5.47675 8.4331 5.41633 8.28117 5.41633H6.22676L4.27091 3.46048V1.97892H5.98969C6.19441 1.97892 6.38344 1.86972 6.48575 1.69248C6.58818 1.51523 6.58818 1.29685 6.48575 1.11959C6.38346 0.942201 6.19428 0.833008 5.98954 0.833008H1.40642C1.2017 0.833008 1.01253 0.942201 0.910212 1.11959C0.807924 1.29683 0.807924 1.51522 0.910212 1.69248C1.0125 1.86972 1.20169 1.97892 1.40642 1.97892H3.12506L3.12481 3.6977ZM7.7083 7.70811L4.27088 7.70797V6.56222H7.7083V7.70811Z"
      fill="currentColor"
    />
  </svg>
);

const webcamIcon = (
  <svg
    width="15"
    height="20"
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49967 14.9998C11.1816 14.9998 14.1663 12.0151 14.1663 8.33317C14.1663 4.65127 11.1816 1.6665 7.49967 1.6665C3.81778 1.6665 0.833008 4.65127 0.833008 8.33317C0.833008 12.0151 3.81778 14.9998 7.49967 14.9998ZM7.49967 14.9998V18.3332M7.49967 18.3332H3.33301M7.49967 18.3332H11.6663M9.99967 8.33317C9.99967 9.71388 8.88039 10.8332 7.49967 10.8332C6.11896 10.8332 4.99967 9.71388 4.99967 8.33317C4.99967 6.95246 6.11896 5.83317 7.49967 5.83317C8.88039 5.83317 9.99967 6.95246 9.99967 8.33317Z"
      stroke="currentColor"
      strokeWidth="1.375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const mobilePlusHealthIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 4V4C9 5.10457 9.89543 6 11 6H13C14.1046 6 15 5.10457 15 4V4"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 13H15"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 10L12 16"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="6"
      y="3"
      width="12"
      height="18"
      rx="4"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const stethoscopeIcon = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 12V16C6.5 16.9293 6.5 17.394 6.57686 17.7804C6.89249 19.3671 8.13288 20.6075 9.71964 20.9231C10.106 21 10.5707 21 11.5 21V21C12.4293 21 12.894 21 13.2804 20.9231C14.8671 20.6075 16.1075 19.3671 16.4231 17.7804C16.5 17.394 16.5 16.9293 16.5 16V13"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 3.1709C13.3348 3.58273 12.5 4.69397 12.5 6.00019V9.00019C12.5 9.93016 12.5 10.3952 12.6022 10.7766C12.8796 11.8119 13.6883 12.6206 14.7235 12.898C15.105 13.0002 15.57 13.0002 16.5 13.0002C17.43 13.0002 17.895 13.0002 18.2765 12.898C19.3117 12.6206 20.1204 11.8119 20.3978 10.7766C20.5 10.3952 20.5 9.93016 20.5 9.00019V6.00019C20.5 4.69397 19.6652 3.58273 18.5 3.1709"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.47993 9.58814C8.5 9.45985 8.5 9.30657 8.5 9C8.5 8.69343 8.5 8.54015 8.47993 8.41186C8.36774 7.69478 7.80522 7.13226 7.08814 7.02007C6.95985 7 6.80657 7 6.5 7C6.19343 7 6.04015 7 5.91186 7.02007C5.19478 7.13226 4.63226 7.69478 4.52007 8.41186C4.5 8.54015 4.5 8.69343 4.5 9C4.5 9.30657 4.5 9.45985 4.52007 9.58814C4.63226 10.3052 5.19478 10.8677 5.91186 10.9799C6.04015 11 6.19343 11 6.5 11C6.80657 11 6.95985 11 7.08814 10.9799C7.80522 10.8677 8.36774 10.3052 8.47993 9.58814Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const edgeBoxIcon = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3999 14.2H10.3999M5.5999 14.2902V14.2M1.3999 13L3.41404 2.92932C3.6384 1.8075 4.6234 1 5.76743 1H15.7042C16.8156 1 17.7816 1.76299 18.039 2.84411L20.5999 13.6M15.8002 9.4H6.20006C3.54903 9.4 1.39997 11.5491 1.40006 14.2002C1.40014 16.8511 3.54915 19 6.20006 19H15.8002C18.4512 19 20.6002 16.851 20.6002 14.2C20.6002 11.549 18.4512 9.4 15.8002 9.4Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export {
  orIcon,
  webcamIcon,
  mobilePlusHealthIcon,
  stethoscopeIcon,
  edgeBoxIcon,
};
