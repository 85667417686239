import { FC, useRef } from 'react';
import { ErrorMessage, Field, FieldProps, useField } from 'formik';
import TextError from './TextError';
import {
  usePhoneInput,
  defaultCountries,
  FlagImage,
  parseCountry,
} from 'react-international-phone';
import 'react-international-phone/style.css';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ListBox } from 'primereact/listbox';
import { InputText } from 'primereact/inputtext';

type PhoneNumberProps = Extract<IFormControlProps, { control: 'phoneNumber' }>;

function formatPhone(phone: string, dialCode: string) {
  phone = phone.replace('+', '').replace(dialCode, '');
  return phone;
}

const CountryOptionTemplate = (option: {
  name: string;
  iso2: string;
  dialCode: string;
}) => {
  return (
    <div className="align-items-center flex">
      <FlagImage iso2={option.iso2} style={{ marginRight: '8px' }} />
      <div>{option.name}</div>
    </div>
  );
};

/**
 * PhoneNumber component.
 *
 * @param {PhoneNumberProps} props - Component properties.
 */
const PhoneNumber: FC<PhoneNumberProps> = (props) => {
  const op = useRef<OverlayPanel | null>(null);

  const [phoneField, , phoneHelper] = useField(props.name);
  const [countryCodeField, , countryCodeHelper] = useField(
    props.countryCodeFieldName
  );

  const { country, setCountry, inputRef, inputValue, handlePhoneValueChange } =
    usePhoneInput({
      defaultCountry: 'us',
      value: `${countryCodeField.value}${phoneField.value}`,
      countries: defaultCountries,
      onChange: (data) => {
        phoneHelper.setValue(formatPhone(data.phone, data.country.dialCode));
        countryCodeHelper.setValue(data.country.dialCode);
      },
    });

  return (
    <Field name={props.name}>
      {({ meta }: FieldProps) => {
        const { label, name, mandatory } = props;

        return (
          <div className="flex flex-col gap-2.5 !text-sm">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <label htmlFor={name} className="text-neutral-700">
                  {label}
                </label>

                {mandatory && (
                  <span className="-translate-y-1 text-xs text-red-500">*</span>
                )}
              </div>

              <ErrorMessage name={name} component={TextError} />
            </div>

            <div
              className={`flex w-full overflow-hidden rounded-lg border border-gray-300 ${
                meta.error && 'border-red-500'
              }`}
            >
              <button
                className="flex items-center justify-center border-r bg-white px-3"
                onClick={(e) => op.current?.toggle(e)}
                type="button"
              >
                {country && country?.iso2 ? (
                  <FlagImage iso2={country.iso2} />
                ) : (
                  <></>
                )}
                <i className="pi pi-chevron-down ml-2 !text-xs"></i>
              </button>

              {/* OverlayPanel for country selection */}
              <OverlayPanel ref={op} className="">
                <ListBox
                  value={country.iso2}
                  onChange={(e) => {
                    setCountry(e.target.value?.iso2);
                  }}
                  options={defaultCountries?.map((item) => {
                    const country = parseCountry(item);
                    return country;
                  })}
                  optionLabel="name"
                  itemTemplate={CountryOptionTemplate}
                  className="md:w-14rem w-full"
                  listStyle={{ maxHeight: '250px' }}
                  filter
                />
              </OverlayPanel>

              <InputText
                className={`grow !border-0 !text-sm`}
                ref={inputRef}
                placeholder={country.format as string}
                type="tel"
                value={inputValue}
                onChange={handlePhoneValueChange}
              />
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default PhoneNumber;
