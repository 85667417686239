import axios from 'axios';
import moment from 'moment-timezone';
import { API_BASE_URL } from 'config/Constants';
import { keycloak } from 'config/Keycloak';
const axiosClient = axios.create();

// axiosClient.defaults.baseURL = '/api';
axiosClient.defaults.baseURL = API_BASE_URL;

// Get time zone abbreviation
const timeZoneAbbr = moment.tz(moment.tz.guess()).format('z');

axiosClient.defaults.headers.common = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Timezone': timeZoneAbbr,
};

axiosClient.interceptors.request.use(
  async (config) => {
    if (keycloak.token) {
      config.headers.Authorization = `Bearer ${keycloak.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }

    return response;
  },
  async (error) => {
    const originalConfig = error.config;

    if (
      error?.response &&
      error.response.status === 401 &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true;

      await keycloak
        .updateToken()
        .then((refreshed) => {
          if (refreshed) {
            console.warn('Token refreshed successfully');
          } else {
            console.warn(
              'Token not refreshed, valid tokens are not available. Attempting to refresh again later.'
            );
            keycloak.logout();
          }
        })
        .catch((error) => {
          console.error('Error refreshing token:', error);
          keycloak.logout();
        });

      originalConfig.headers.Authorization = `Bearer ${keycloak.token}`;

      return axiosClient(originalConfig);
    }

    return Promise.reject(error.response.data);
  }
);

export default axiosClient;
